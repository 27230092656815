import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SharedAppComponent } from '../../../shared/src/lib/shared-app.component';
import { LoaderComponent } from '../../../shared/src/lib/components/loader/loader.component';
import { ToastNotificationsComponent } from '@serene-dev/toast-notifications';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    imports: [RouterOutlet, LoaderComponent, ToastNotificationsComponent]
})
export class AppComponent extends SharedAppComponent {
  constructor() {
    super();
    // this.menuF.setMenu(this.appService.candidateMenu);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
